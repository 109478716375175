
import { Component } from "vue-property-decorator";
import JobOfferForm from "@/components/ats/JobOffer/JobOfferForm.vue";
import { JobOfferCommand, JobService } from "@/data";
import { Action, State } from "vuex-class";
import { IAddress, IJobOffer, StoreAction } from "@/types";
import { mixins } from "vue-class-component";
import TitleManager from "@/mixins/TitleManager.vue";
import clonedeep from "lodash.clonedeep";
import ActionButton from "@/components/ActionButton.vue";
import NewIcon from "@/components/NewIcon.vue";
import { showModal } from "@/helpers/callables";
import router from "@/router";

const REQUIRED_FIELDS = ["name", "jobId", "description", "applicantProfile", "applicantDegree", "functions", "businessSectors", "client"];

const ADDRESS_FIELDS = ["city", "zip"];

const isValid = (value: any) => {
    if (Array.isArray(value)) return value.length > 0;
    return !["", null, undefined].includes(value);
};

@Component({
    name: "Add",
    components: {
        JobOfferForm,
        ActionButton,
        NewIcon,
    },
})
export default class Add extends mixins(TitleManager) {
    pageTitle = this.$t("page.jobOffer.title");
    loading = false;
    errors: any = {};
    jobOffer: IJobOffer = {
        agencyId: "", // a remplir au beforeMount
        ownerId: "", // a remplir au beforeMount
        missionId: "",
        jobId: "",
        name: "",
        description: "",
        address: {
            city: "",
            zip: "",
        },
        jobType: "daily",
        jobContract: "interim",
        jobStatus: "employee",
        jobDuration: "1",
        businessSectors: [],
        functions: [],
        applicantProfile: "",
        applicantDegree: "",
        applicantExperience: "",
        salaryFrom: "10000",
        salaryTo: "15000",
        salaryPer: "year",
        services: {
            [JobService.talentplug]: {
                jobBoards: [],
                active: false,
            },
            [JobService.interactionweb]: {
                jobBoards: [],
                active: false,
            },
            [JobService.merciii]: {
                jobBoards: [],
                active: false,
            },
            [JobService.vigijobs]: {
                jobBoards: [],
                active: false,
            },
        },
        candidates: [],
    };

    @State("missionDataForJobOffer") missionDataForJobOffer!: any;

    @Action("actions/createJobOffer") createJobOffer!: StoreAction;
    @Action("actions/createJobOfferMission") createJobOfferMission!: StoreAction;
    @Action("actions/sendCommandJobOffer") sendCommandJobOffer!: StoreAction;

    get fromMission() {
        return this.$route.query.fromMission === "1";
    }

    fieldsValidation() {
        const validations = REQUIRED_FIELDS.map((field: string) => [field, isValid(this.jobOffer[field as keyof IJobOffer])]);
        const addrValidations = ADDRESS_FIELDS.map((field: string) => [
            field,
            isValid(this.jobOffer.address?.[field as keyof Pick<IAddress, "city" | "zip">]),
        ]);
        const filtredValidations = [...validations, ...addrValidations].filter((item) => !item[1]);
        return filtredValidations.map(([key]) => [key, { id: "Validation.Any.Empty", key }]);
    }

    async posteJoboffer(jobOfferId: string) {
        try {
            const res = await this.sendCommandJobOffer({
                jobOfferId,
                command: JobOfferCommand.post,
                data: {
                    service: this.jobOffer.services,
                },
            });
            if (res.offerUrl) {
                showModal.call(this, "RedirectionModal");
                location.href = res.offerUrl;
            } else {
                this.$router.back();
            }
        } catch (e) {
            console.log(e);
        }
    }

    async create(post = false) {
        this.loading = true;
        const validationErrors = this.fieldsValidation();
        if (validationErrors.length > 0) {
            this.errors = Object.fromEntries(validationErrors);
            this.loading = false;
            return;
        }
        try {
            const copy: any = clonedeep(this.jobOffer);

            if (typeof copy.jobId !== "string") {
                copy.jobId = copy.jobId[0].value;
            }

            let res;

            if (this.fromMission && this.jobOffer.missionId) {
                res = await this.createJobOfferMission(copy);
            } else {
                res = await this.createJobOffer(copy);
            }
            if (post) {
                await this.posteJoboffer(res._id);
            } else {
                this.$router.back();
            }
        } catch (e) {
            const error = e as { errors: any };
            console.log("errors:", error.errors);
            this.errors = error.errors;
        }
        this.loading = false;
    }

    goBack() {
        router.back();
    }
}
